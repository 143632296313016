<template>
  <div class="iframe_overflow">
    <iframe class="iframe_div" :src="url" v-if="url"></iframe>
    <div class="iframe_shadow" v-if="!type"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      type: true
    };
  },
  methods: {
    init() {
      this.url = IOT_CONFIG.paths["dashboard"];
      let key = this.$route.query.type;
      if (key === 'view') {
        this.type = false;
      } else {
        this.type = true;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang='less'>

</style>